import React from "react";
import tw, { css } from 'twin.macro';

const articleStyles = css`
  h1, h2, h3, h4, h5, h6 {
    ${tw`tracking-wide light:text-gray-900 dark:text-gray-100`}
  }

  h1 {
    ${tw`text-4xl font-bold mt-28 first:mt-0 mb-8`}
  }

  h2 {
    ${tw`text-3xl mt-24 first:mt-0 mb-8`}
  }

  h3 {
    ${tw`text-2xl mt-20 mb-8`}
  }

  h4 {
    ${tw`text-xl mt-16 mb-8`}
  }

  h5 {
    ${tw`text-lg mt-12 mb-8`}
  }

  h6 {
    ${tw`text-base mt-8 mb-8`}
  }

  p, ul, ol, table, pre, blockquote, hr {
    ${tw`mb-8`}
  }

  blockquote {
    ${tw`pl-8 border-l-4 border-gray-300 text-gray-400`}
  }

  table {
    ${tw`table-auto border-separate`}

    th, td {
      ${tw`px-4 py-2`}
    }
  }

  ul {
    ${tw`list-disc list-inside`}
  }

  ol {
    ${tw`list-decimal list-inside`}
  }

  code {
    ${tw`font-mono light:bg-red-100 light:text-red-700 dark:bg-gray-900 dark:text-gray-200 rounded-sm`}
  }

  pre > code {
    ${tw`inline light:bg-transparent light:text-current dark:bg-transparent dark:text-current`}
  }

  pre {
    ${tw`font-mono rounded-lg p-8 dark:bg-gray-900`}
  }

  hr {
    ${tw`border-t-2 light:border-gray-300 dark:border-gray-900`}
  }

  a {
    ${tw`hover:underline light:text-pink-700 light:hover:text-pink-600 light:active:text-pink-800 dark:text-pink-600 dark:hover:text-pink-500 dark:active:text-pink-700`}
  }
`;

const Article = (props) => {
  return (
    <div css={articleStyles} {...props} />
  );
}

export default Article;
