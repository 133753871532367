import React from "react";
import tw, { css } from 'twin.macro';
import { Link } from "gatsby";


export const Post = (props) => {
  return (
    <article tw="max-w-screen-md mx-auto" {...props} />
  );
}

export const PostHeader = ({ title, children }) => {
  return (
    <header tw="relative mb-16">
      <h1 tw="text-3xl md:text-5xl mb-4 tracking-wide leading-snug font-bold light:text-gray-900 dark:text-gray-100">
        {title}
      </h1>
      {children}
    </header>
  );
}

const TagStyle = css`
  ${tw`border py-1 px-3 text-sm tracking-wider rounded-md`}
  ${tw`light:border-gray-300 light:text-gray-400 dark:border-gray-600 dark:text-gray-500`}
  ${tw`hover:light:text-gray-600 hover:light:border-gray-500`}
  ${tw`hover:dark:text-gray-700 hover:dark:border-gray-800`}
`;

export const Tag = ({ tag, path }) => {
  return (
    <li tw="inline">
      <Link css={TagStyle} to={`${path}/${tag}/`}>
          # {tag}
      </Link>
    </li>
  );
}

export const TagList = ({ tags, path }) => {
  return (
    <ul tw="mb-16 max-w-screen-md mx-auto">
      { tags.map((tag, index) => {
        return (
          <Tag key={index} tag={tag} path={path} />
        )
      })}
    </ul>
  );
}
