import React from "react";
import { Link, graphql } from "gatsby";
import { DateTime } from "luxon";
import tw, { css } from 'twin.macro';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { MDXRenderer } from "gatsby-plugin-mdx";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Article from "../components/article";
import { Post, PostHeader, TagList } from "../components/post";

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/pro-light-svg-icons';

library.add([faAngleLeft, faAngleRight]);

const BlogPostTemplate = ({ data, location }) => {
  const post = data.mdx;
  const siteTitle = data.site.siteMetadata?.title || `Title`;
  const { previous, next } = data;

  const image = getImage(post.frontmatter.eyecatch);

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />

      <Post>
        <PostHeader title={post.frontmatter.title}>
          <ul tw="flex gap-2 text-sm light:text-gray-400 dark:text-gray-500">
            <li>
              <time dateTime={post.frontmatter.date} title={`Publish Date: ${DateTime.fromISO(post.frontmatter.date).toFormat('yyyy.MM.dd')}`}>
                {DateTime.fromISO(post.frontmatter.date).toFormat('yyyy.MM.dd')}
              </time>
            </li>
            <li>
              { post.frontmatter.categories && <ul tw="ml-2 inline-block">
                { post.frontmatter.categories.map((cat, index) => {
                  return (
                    <li key={index} tw="inline">
                      <Link to={`/articles/cat/${cat}/`} tw="text-sm tracking-wider hover:underline">{cat}</Link>
                    </li>
                  )
                })}
              </ul> }
            </li>
          </ul>

          { image && <GatsbyImage image={image} tw="mt-16 rounded-md" alt={post.frontmatter.title} loading="lazy" /> }
        </PostHeader>

        <Article>
          <MDXRenderer>{post.body}</MDXRenderer>
        </Article>
        { post.frontmatter.tags && <TagList tags={post.frontmatter.tags} path="/articles/tag" /> }

        <nav tw="mt-12">
          <ul tw="flex flex-row flex-wrap justify-between">
            <li>
              {previous && (
                <Link to={`/articles${previous.fields.slug}`} tw="hover:underline" rel="prev">
                  <FontAwesomeIcon tw="mr-4" icon={["fal", "angle-left"]} />
                  <span>{previous.frontmatter.title}</span>
                </Link>
              )}
            </li>
            <li>
              {next && (
                <Link to={`/articles${next.fields.slug}`} tw="hover:underline" rel="next">
                  <span>{next.frontmatter.title}</span>
                  <FontAwesomeIcon tw="ml-4" icon={["fal", "angle-right"]} />
                </Link>
              )}
            </li>
          </ul>
        </nav>
      </Post>
    </Layout>
  )
}

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
      }
    }
    mdx(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      body
      frontmatter {
        title
        date
        description
        eyecatch {
          childImageSharp {
            gatsbyImageData(
              placeholder: DOMINANT_COLOR
              aspectRatio: 1.77777777778
            )
          }
        }
        categories
        tags
      }
    }
    previous: mdx(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: mdx(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`;
